<template>
  <div v-loading="loading" element-loading-text="请求中">
    <div class="device">
      <span style="line-height: 30px;">UID:</span>
      <el-input v-model="uid" size="small" placeholder="请输入UID"></el-input>

      <el-button type="primary" size="small" @click="commitLog()">
        <i class="el-icon-search el-icon"></i>查询
      </el-button>

      <span style="line-height: 30px;margin-left:20px;">Tag:</span>
      <!--tag查询-->
      <el-input style="width: 400px;" :clearable='true' size="small" v-model="tagData" @input="levelChange" placeholder="tag1,tag2">
        <el-select style="width:170px" collapse-tags v-model="saveTags" multiple slot="prepend" @change="levelChange" placeholder="请选择tag">
          <el-option v-for="item in tagOptions" :key="item.value" :label="item" :value="item"></el-option>
        </el-select>
        <el-button slot="append" icon="el-icon-setting" @click="editReady"></el-button>
      </el-input>
      <span style="line-height: 30px;">Data:</span>
      <!--data查询-->
      <el-input style="width: 200px;" :clearable='true' size="small" v-model="inputData" @input="levelChange" placeholder="关键字查询"></el-input>
      <span style="line-height: 30px;">level:</span>
      <!--lever选择-->
      <el-select style="width: 130px;" size="small" v-model="levelnum" @change="levelChange" placeholder="请选择">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>

    <!--log信息部分-->
    <div class="res">
      <template>
        <RecycleScroller id="rs" class="scroller" :items="beforeTableData" :item-size="20" key-field="id" v-slot="{ item }">
          <el-row>
            <el-col :span="4">
              <span :class="'rowtitele_'+item.level" class="date">[{{ item.date }}]</span>
            </el-col>
            <el-col :span="3">
              <span :class="'rowtitele_'+item.level" class="tag">{{item.tag}}</span>
            </el-col>
            <el-col :span="17">
              <span style="color:#ffffff">#</span>
              <span :class="'rowtitele_'+item.level" class="data">{{item.data}}</span>
            </el-col>
          </el-row>
        </RecycleScroller>
      </template>
    </div>
    <div class="foot">
      <span style="padding: 0 10px;">全部log条数：{{tableDataLenght}}<i style="padding: 0 5px;" class="el-icon-delete el-icon" @click="clearLog"></i></span>
      <el-button size="mini" @click="copyAll" round>全部复制</el-button>

    </div>
    <!-- 设备配置 -->
    <Dialog :data="editDialog" @sure="setTags">
      <div style="padding: 0 10%;">
        <div class="c-item">
          <span>tags数据：</span>
          <el-input type="textarea" autosize style="width:300px" v-model="editDialog.data.tags"></el-input>
        </div>
        <span style="font-size:10px;color: #959595;">自定义tag方法：tag1,tag2,tag3... 分隔逗号需英文状态下输入</span>
      </div>
    </Dialog>
  </div>
</template>
<script>
import { Base64 } from 'js-base64'
import Dialog from '../../components/Dialog.vue';
export default {
  components: {
    Dialog
  },
  data() {
    return {
      text: '',//接收的log
      maxLines: 120,
      timer: null,
      enableLogcatAcked: false,//是否有返回
      loading: false,
      uid: '',
      ruid: '',
      tableData: [],
      options: [{
        value: '',
        label: 'all'
      }, {
        value: '4',
        label: 'verbose'
      }, {
        value: '3',
        label: 'debug'
      }, {
        value: '2',
        label: 'info'
      }, {
        value: '1',
        label: 'warn'
      }, {
        value: '0',
        label: 'error'
      }],
      // 编辑dialog
      editDialog: {
        show: false,
        title: 'tags配置',
        width: '600px',
        data: {
          tags: '',
        }
      },
      tagOptions: [],//用户自定义tags数据
      levelnum: '',//等级查询
      tagData: '',
      inputData: '',//data内数据查询
      verboseTableData: [],//verbose信息
      debugTableData: [],//debug信息
      infoTableData: [],//info信息
      warnTableData: [],//warn信息
      errorTableData: [],//error信息
      allTableData: [],//all信息
      beforeTableData: [],
      tableDataLenght: 0,//总条数
      saveTags: []
    }
  },

  created() {

  },

  mounted() {
    this.ws.addCallback('Logcat', (res) => {
      this.on_Logcat(res)
    })
    this.ws.addCallback('EnableLogcat.Ack', (res) => {
      console.log("日志已开启");

      if (!this.enableLogcatAcked) {
        this.enableLogcatAcked = true
      }
    })
    this.ws.addCallback('userDataStoreAck', (res) => {
      this.on_userDataStoreAck(res)
    })
    this.ws.addCallback('setUserDataStoreAck', (res) => {
      if (!res.error) {
        this.common.cResultOk(this);
        this.editDialog.show = false
        this.getTags()
      } else {
        this.common.cResultNo(this);
      }
    })
    this.getTags()//获取用户自定义的tags
    this.getLogUid()//读取上次查询的uid
  },
  watch: {
    'tableData': 'scrollToBottom'
  },

  methods: {
    //自动滚动
    scrollToBottom: function () {
      this.$nextTick(() => {
        var div = document.getElementById('rs')
        div.scrollTop = div.scrollHeight
      })
    },

    setHotDevices() {
      if (this.ruid == "" || this.ruid !== this.uid) {
        let uids = this.uid + ",";
        let json = {
          "cmd": "setHotDevices",
          "uids": uids
        }
        this.ws.send(json);
      }

    },
    //请求log数据
    logRequest() {
      let uid = this.uid
      if (uid) {
        let json = {
          "cmd": "EnableLogcat",
          "uid": uid,
          "second": 45
        }
        this.ws.send(json);
      }
    },
    on_Logcat(res) {
      if (this.uid != res.uid) {
        return;
      }
      this.seveData(res)
    },
    //查询
    commitLog() {

      this.setHotDevices()

      //避免重复请求
      if (this.ruid == "" || this.ruid !== this.uid) {
        this.ruid = this.uid
        if (this.uid) {
          clearTimeout(this.timer)
          this.timer = null
        }
        this.logRequest()
      }
      let timer = this.timer
      if (timer == null) {
        this.timer = setInterval(() => {
          this.logRequest()  //30秒请求一次
        }, 30 * 1000)
      }
      this.setLogUid()//存储Uid
    },
    //存储数据
    seveData(json) {
      let time = json.time //时间
      let tag = json.tag//tag
      let level = json.level//等级
      let text = Base64.decode(json.data)//内容
      let item = {
        id: Math.ceil(Math.random() * 10000),
        date: time,
        tag: tag,
        level: level,//Math.floor(Math.random() * 5),//,
        data: text
      }
      this.tableData.push(item)

      if (item.level == '4') {
        this.verboseTableData.push(item)
      } else if (item.level == '3') {
        this.debugTableData.push(item)
      } else if (item.level == '2') {
        this.infoTableData.push(item)
      } else if (item.level == '1') {
        this.warnTableData.push(item)
      } else if (item.level == '0') {
        this.errorTableData.push(item)
      }
      this.allTableData = this.tableData

      this.tableDataLenght = this.tableData.length//计次
      this.filtrateData()

    },
    //改变筛选条件更新数据
    levelChange() {
      this.filtrateData()
    },
    //筛选数据
    filtrateData() {
      //筛选tag
      let data1 = []
      let data2 = []
      let befordata = []

      if (this.levelnum == '') {
        befordata = this.allTableData
      } else if (this.levelnum == '4') {
        befordata = this.verboseTableData
      } else if (this.levelnum == '3') {
        befordata = this.debugTableData
      } else if (this.levelnum == '2') {
        befordata = this.infoTableData
      } else if (this.levelnum == '1') {
        befordata = this.warnTableData
      } else if (this.levelnum == '0') {
        befordata = this.errorTableData
      }
      if (this.tagData != '' || this.saveTags.length > 0) {
        let tags = []
        if (this.saveTags.length > 0) {
          tags = [...this.tagData.split(","), ...this.saveTags]
        } else {
          tags = this.tagData.split(",")
        }
        //筛选tag有值
        befordata.forEach((item) => {
          tags.forEach((tagitem) => {
            if (tagitem == item.tag) {
              data1.push(item)
            }
          })
        })
      } else {
        data1 = befordata
      }
      //筛选data查询有值
      if (this.inputData != '') {
        let reg = new RegExp(this.inputData)
        if (data1.length != 0) {
          data1.forEach((item) => {
            if (reg.test(item.data)) {
              data2.push(item)
            }
          })
        }
      } else {
        data2 = data1
      }
      this.beforeTableData = data2
    },
    //清除log数据。不停止计时器
    clearLog() {
      this.verboseTableData = []//verbose信息
      this.debugTableData = []//debug信息
      this.infoTableData = []//info信息
      this.warnTableData = []//warn信息
      this.errorTableData = []//error信息
      this.allTableData = []//all信息
      this.beforeTableData = []
      this.tableData = []
      this.tableDataLenght = 0
    },
    // 编辑tags
    editReady() {
      this.editDialog.show = true;
      // this.editDialog.data = {
      //   tags: '2222'
      // }
    },
    //存储用户自定义的Tags
    setTags() {
      let tags = this.editDialog.data.tags
      if (tags != undefined) {
        let json = {
          "cmd": "setUserDataStore",
          "id": "logtags",
          "tag": "logtags",
          "data": { "tags": tags }
        };
        this.ws.send(json);
      }
    },
    //获取用户存储的tags
    getTags() {
      let json = {
        "cmd": "userDataStore",
        "id": "logtags",
        "tag": "logtags",/* 自定义标记*/
      };

      this.ws.send(json)
    },
    //设置用户自定义tags
    on_userDataStoreAck(res) {
      if (res.tag != "logtags") {
        return
      }
      let tags = res.items[0].data['tags']
      if (tags == undefined) {
        return
      }

      this.editDialog.data.tags = tags
      if (tags == '') {
        this.tagOptions = []
      } else {
        this.tagOptions = tags.split(",")
      }
      // console.log("getUserDataStoreAck:", tags);
    },
    //复制全部log
    copyAll() {
      let allData = this.allTableData
      let copy = ''
      if (allData.length > 0) {
        allData.forEach((item) => {
          copy += "[" + item.date + "]" + item.tag + "#" + item.data + "\n"
        })
      }
      var textarea = document.createElement('textarea');
      document.body.appendChild(textarea);
      textarea.value = copy;
      textarea.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        this.common.cResultOk(this);

      }
      document.body.removeChild(textarea);
    },
    //存储Uid
    setLogUid() {
      localStorage.setItem("logUid", this.uid);
    },
    //读取Uid
    getLogUid() {
      let loguid = localStorage.getItem("logUid")
      let uid = loguid != undefined ? loguid : ""
      this.uid = uid
    }
  },
  beforeDestroy() {
    //清理定时器
    if (this.timer != null) {
      clearTimeout(timer)
      this.timer = null
    }
  }

}
</script>
<style lang="scss" scoped>
$text_color: #808080; //文字默认颜色
$color_error: #ff0000; //error 0
$color_warn: #ff00ff; //warn 1
$color_info: #0000ff; //info 2
$color_debug: #000000; // debug 3
$color_verbose: #808080; //verbose 4

::v-deep .el-select__tags-text {
  display: inline-block;
  max-width: 56px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
::v-deep .el-tag__close.el-icon-close {
  top: -7px;
}
.device {
  font-size: 14px;
}
.scroller {
  height: 100%;
  background-color: #ffffff;
}

.date {
  height: 32%;
  padding: 0 12px;
  // display: flex;
  align-items: center;
  color: $text_color;
}
.tag {
  height: 32%;
  padding: 0 5px;
  // display: flex;
  align-items: center;
  color: $text_color;
}
.data {
  height: 32%;
  margin-left: 2%;
  padding: 0 5px;
  // display: flex;
  align-items: center;
  color: $text_color;
  max-width: 100%;
  display: inline-block;
  overflow-wrap: break-word; /*  css3属性，当内容超过指定容器的边界时是否断行  */
  text-align: left;
}
.res {
  font-size: 16px;
  margin-top: 20px;
  margin-right: 20px;
  // min-height: 400px;
  // background-color: black;

  height: calc(100vh - 247px);
}
.row_0 {
  background-color: $color_error;
}
.row_1 {
  background-color: $color_warn;
}
.row_2 {
  background-color: $color_info;
}
.row_3 {
  background-color: $color_debug;
}
.row_4 {
  background-color: null;
}
.rowtitele_0 {
  color: $color_error;
}
.rowtitele_1 {
  color: $color_warn;
}
.rowtitele_2 {
  color: $color_info;
}
.rowtitele_3 {
  color: $color_debug;
}
.rowtitele_4 {
  color: $color_verbose;
}
.el-input {
  width: 140px;
  margin: 0 20px;
  font-size: 13px;
}
</style>